import React, { useContext, useState, useEffect } from "react";
import { List } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { authContext } from "../components/Authentication";
import { AppPageLayout } from "../layouts";

interface ListItem {
  title: string;
  description: string;
  path: string;
  action: string;
}
const listItems = [
  {
    title: "Manage Courses",
    description:
      "Configure the courses available in clearing, their automatic offer settings and entry requirements.",
    path: "/app/config/courses",
    action: "courses:read",
  },
];

export const AppPage: React.SFC = () => {
  const [items, setItems] = useState<ListItem[]>([]);
  const auth = useContext(authContext);

  useEffect(() => {
    const items: ListItem[] = [];
    for (const i of listItems) if (auth.can(i.action, undefined)) items.push(i);
    setItems(items);
  }, [auth.authenticated, auth]);

  return (
    <AppPageLayout title="Tasks" breadcrumbs={[{ title: "Home" }]}>
      <Helmet>
        <title>Clearing Administration</title>
      </Helmet>
      <List
        dataSource={items}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <Link to={item.path}>
                  <strong>{item.title}</strong>
                </Link>
              }
              description={item.description}
            />
          </List.Item>
        )}
      />
    </AppPageLayout>
  );
};
