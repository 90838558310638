import React, { useContext } from "react";
import { authContext } from "./components/Authentication";

import { Layout, Menu, Typography, Breadcrumb, Divider } from "antd";
import { Link } from "react-router-dom";
const { Header, Content, Footer } = Layout;

const TopMenu: React.FC = () => {
  const auth = useContext(authContext);
  return (
    <Menu theme="dark" mode="horizontal" selectable={false}>
      {auth.authenticated ? (
        <Menu.Item
          onClick={() => auth.logout()}
          style={{ float: "right" }}
          key="1"
        >
          {auth.account!.name} | Logout
        </Menu.Item>
      ) : (
        <Menu.Item
          onClick={() => auth.login()}
          style={{ float: "right" }}
          key="1"
        >
          Login
        </Menu.Item>
      )}
    </Menu>
  );
};

type BreadcrumbTrail = { title: string; path?: string }[];

interface AppPageLayoutProps {
  title?: string;
  breadcrumbs?: BreadcrumbTrail;
}

export const AppPageLayout: React.FC<AppPageLayoutProps> = ({
  title,
  breadcrumbs,
  children,
}) => {
  return (
    <>
      <Layout className="layout">
        <Layout>
          <Header>
            <TopMenu />
          </Header>
          <Content style={{ padding: "0 50px" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              {breadcrumbs &&
                breadcrumbs.map((b, i) => (
                  <Breadcrumb.Item key={i}>
                    {b.path ? <Link to={b.path}>{b.title}</Link> : b.title}
                  </Breadcrumb.Item>
                ))}
            </Breadcrumb>
            <div className="layout-content">
              {title && (
                <>
                  <Typography.Title>{title}</Typography.Title>
                  <Divider />
                </>
              )}
              {children}
            </div>
            <Footer style={{ textAlign: "center" }}></Footer>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
