import React, {
  createContext,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import * as MSAL from "msal";
import {
  MsalAuthProvider,
  AzureAD,
  IAzureADFunctionProps,
  AuthenticationState,
} from "react-aad-msal";
import { Route, RouteProps } from "react-router-dom";

import { UnauthorisedPage } from "../pages/Unauthorised";
import { AuthenticatingPage } from "../pages/Authenticating";

const POST_LOGIN_REDIRECT_KEY = "post_login_redirect";

interface IAuthContext {
  authenticated: boolean;
  authenticating: boolean;
  account: MSAL.Account | null;
  error: MSAL.AuthError | null;
  login(): void;
  logout(): void;
  can(action: string, data: object | undefined): boolean;
  token(): Promise<string | undefined>;
}

const authContext = createContext<IAuthContext>({
  authenticated: false,
  authenticating: false,
  account: null,
  error: null,
  login: () => {},
  logout: () => {},
  can: () => false,
  token: () => new Promise(() => null),
});

interface AuthenticationProps {
  provider: MsalAuthProvider;
  rules: AccessRules;
}

const Authentication: React.FC<AuthenticationProps> = ({
  provider,
  children,
  rules,
}) => {
  const location = useLocation();
  const history = useHistory();

  const loginFunc = useCallback(
    (f: () => void, pathname: string): (() => void) => {
      return () => {
        sessionStorage.setItem(POST_LOGIN_REDIRECT_KEY, pathname);
        f();
      };
    },
    []
  );

  const tokenFunc = useCallback(async (): Promise<string | undefined> => {
    if (provider.authenticationState !== AuthenticationState.Authenticated)
      return undefined;

    const tokenResponse = await provider.getAccessToken();
    if (tokenResponse) return tokenResponse.accessToken;
  }, [provider]);

  useEffect(() => {
    provider.registerAuthenticationStateHandler((a: AuthenticationState) => {
      if (a !== AuthenticationState.Authenticated) return;
      const redirect = sessionStorage.getItem(POST_LOGIN_REDIRECT_KEY);
      sessionStorage.removeItem(POST_LOGIN_REDIRECT_KEY);
      if (redirect && redirect !== "/") history.push(redirect);
    });
  });

  const canFunc = useCallback(
    (
      roles: string[]
    ): ((action: string, data: object | undefined) => boolean) => {
      return (action: string, data: object | undefined) => {
        for (const role of roles) {
          const permissions = rules[role];
          if (!permissions) continue;

          const staticPermissions = permissions.static;
          if (staticPermissions && staticPermissions.includes(action))
            return true;
        }

        return false;
      };
    },
    [rules]
  );

  return (
    <AzureAD provider={provider}>
      {({
        login,
        logout,
        error,
        authenticationState,
        accountInfo,
      }: IAzureADFunctionProps) => {
        return (
          <authContext.Provider
            value={{
              login: loginFunc(login, location.pathname),
              logout,
              error,
              authenticated:
                authenticationState === AuthenticationState.Authenticated,
              authenticating:
                authenticationState === AuthenticationState.InProgress,
              account: accountInfo ? accountInfo.account : null,
              can: canFunc(
                accountInfo
                  ? ((accountInfo.account!.idTokenClaims
                      .roles as unknown) as string[])
                  : []
              ),
              token: tokenFunc,
            }}
          >
            {children}
          </authContext.Provider>
        );
      }}
    </AzureAD>
  );
};

export interface AccessRules {
  [key: string]: { static: string[] };
}

const AuthError: React.FC = () => {
  const auth = useContext(authContext);
  if (!auth.error) return null;

  if (auth.error.errorMessage.match(/^AADSTS50105/))
    return <h2>You are not assigned to this application.</h2>;

  return (
    <>
      <h1>{auth.error.errorCode}</h1>
      <h2>{auth.error.errorMessage}</h2>
    </>
  );
};

interface CanProps {
  action: string;
  data?: object;
  yes?: React.ReactElement;
  no?: React.ReactElement;
}

export const Can: React.FC<CanProps> = (props) => {
  const auth = useContext(authContext);
  const yes = props.yes || <>{props.children}</> || null;
  return auth.can(props.action, props.data) ? yes : props.no || null;
};

const Login: React.FC = ({ children }) => {
  const auth = useContext(authContext);
  const history = useHistory();

  if (auth.authenticating) return <AuthenticatingPage />;
  if (auth.authenticated) return <>{children}</>;
  if (auth.error) history.push("/");
  auth.login();
  return null;
};

interface ProtectedRouteProps extends RouteProps {
  action: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  action,
  ...rest
}) => {
  return (
    <>
      <Login />
      <Route
        {...rest}
        render={() => (
          <Can
            action={action}
            yes={<>{children}</>}
            no={<UnauthorisedPage />}
          />
        )}
      />
    </>
  );
};

export { Authentication, authContext, AuthError, Login, ProtectedRoute };
