import { AccessRules } from "./components/Authentication";

export const MSAL_CONFIG = {
  auth: {
    clientId: "c5251a52-8bcf-4e72-bc90-10ad80ffb982",
    authority:
      "https://login.microsoftonline.com/93e6beba-c4aa-4731-af5d-d735b097eadb",
    redirectUri: origin,
    postLogoutRedirectUri: origin,
    navigateToLoginRequestUrl: false,
  },
};

export const ACCESS_RULES: AccessRules = {
  CourseAdmin: {
    static: [
      "courses:read",
      "courses:create",
      "courses:update",
      "courses:geckosync",
    ],
  },
  GlobalAdmin: {
    static: [
      "courses:read",
      "courses:create",
      "courses:update",
      "courses:delete",
      "courses:geckosync",
    ],
  },
};
