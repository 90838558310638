import { loader } from "graphql.macro";
import { makeExecutableSchema } from "@graphql-tools/schema";
import { Course } from "./__generated__/Course";
import { GeckoSyncStatus } from "./__generated__/globalTypes";
import { CourseAttributes } from "./__generated__/globalTypes";
const schemaString = loader("../schema.graphql");

let courses: Course[] = [
  {
    id: "C1",
    courseCode: "EDES",
    ucasCourseCode: "A100",
    title: "BA (Hons) Education",
    openToApplications: true,
    automaticOfferEnabled: true,
    geckoSyncStatus: GeckoSyncStatus.SYNCED,
    collegeLocations: ["WHC", "OAK"],
    collegeDropdownOption: true,
    ucasTariffPoints: 90,
    gcseEnglishLevel: "3",
    gcseMathsLevel: "4",
    twoPlusALevelQualifications: true,
    huntGroupId: 0,
    __typename: "Course",
  },
];

const resolvers = {
  Query: {
    courses: () => {
      return courses;
    },
    course: (_parent: any, { id }: Course) => {
      for (const course of courses) if (course.id === id) return course;
      return null;
    },
  },
  Mutation: {
    createCourse: (_parent: any, { attributes }: any) => {
      const id = `C${courses.length + 1}`;
      const course = {
        geckoSyncStatus: "PENDING",
        id: id,
        ...attributes,
      };
      courses.push(course);
      return course;
    },
    updateCourse: (
      _parent: any,
      { id, attributes }: { id: string; attributes: CourseAttributes }
    ) => {
      for (const course of courses)
        if (course.id === id) {
          Object.assign(course, attributes, {
            geckoSyncStatus: GeckoSyncStatus.PENDING,
          });
          return course;
        }
    },
  },
};

export const schema = makeExecutableSchema({
  typeDefs: schemaString,
  resolvers: resolvers,
});
