import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { MSAL_CONFIG } from "./config";

export const provider = new MsalAuthProvider(
  MSAL_CONFIG,
  {
    scopes: ["https://herts365.onmicrosoft.com/clearingapi/user_impersonation"],
  },
  {
    loginType: LoginType.Redirect,
  }
);
