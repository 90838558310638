import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Space, Typography, Button } from "antd";
import { ControlOutlined } from "@ant-design/icons";
import { AuthError } from "../components/Authentication";

export const HomePage: React.SFC = () => {
  const history = useHistory();

  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Col>
        <Space direction="vertical" align="center" size="middle">
          <ControlOutlined
            style={{ fontSize: "8rem", color: "#262626", marginBottom: 0 }}
          />
          <Typography.Title style={{ marginBottom: 0 }}>
            Clearing Administration
          </Typography.Title>
          <Button onClick={() => history.push("/app")} type="primary">
            Login
          </Button>
          <AuthError />
        </Space>
      </Col>
    </Row>
  );
};
