/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum GeckoSyncStatus {
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  SYNCED = "SYNCED",
}

export interface CourseAttributes {
  title?: string | null;
  courseCode?: string | null;
  ucasCourseCode?: string | null;
  openToApplications?: boolean | null;
  automaticOfferEnabled?: boolean | null;
  collegeLocations?: (string | null)[] | null;
  collegeDropdownOption?: boolean | null;
  ucasTariffPoints?: number | null;
  gcseEnglishLevel?: string | null;
  gcseMathsLevel?: string | null;
  twoPlusALevelQualifications?: boolean | null;
  huntGroupId?: number | null;
  school?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
