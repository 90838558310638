import gql from "graphql-tag";

const Course = gql`
  fragment Course on Course {
    id
    title
    courseCode
    ucasCourseCode
    openToApplications
    automaticOfferEnabled
    collegeLocations
    collegeDropdownOption
    ucasTariffPoints
    gcseEnglishLevel
    gcseMathsLevel
    twoPlusALevelQualifications
    geckoSyncStatus
    huntGroupId
    school
  }
`;

export const GET_COURSES = gql`
  query GetCourses {
    courses {
      id
      title
      courseCode
      ucasCourseCode
      openToApplications
      automaticOfferEnabled
      geckoSyncStatus
      school
    }
  }
`;

export const GET_COURSE = gql`
  query GetCourse($id: ID!) {
    course(id: $id) {
      ...Course
    }
  }
  ${Course}
`;

export const CREATE_COURSE = gql`
  mutation CreateCourse($courseAttributes: CourseAttributes!) {
    createCourse(attributes: $courseAttributes) {
      ...Course
    }
  }
  ${Course}
`;

export const UPDATE_COURSE = gql`
  mutation UpdateCourse($id: ID!, $courseAttributes: CourseAttributes!) {
    updateCourse(id: $id, attributes: $courseAttributes) {
      ...Course
    }
  }
  ${Course}
`;

export const SYNC_COURSES = gql`
  mutation SyncCoursesToGecko {
    syncCoursesToGecko {
      id
      title
      courseCode
      openToApplications
      automaticOfferEnabled
      geckoSyncStatus
    }
  }
`;
