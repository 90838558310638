import React, { useCallback } from "react";
import { Row, Col, Spin, Typography, Button, message } from "antd";
import { useMutation } from "@apollo/react-hooks";

import { SYNC_COURSES } from "../queries";
import { SyncCoursesToGecko } from "../__generated__/SyncCoursesToGecko";

import { SyncOutlined } from "@ant-design/icons";

export const GeckoSync: React.FC = () => {
  const [syncCourses, { loading }] = useMutation<SyncCoursesToGecko>(
    SYNC_COURSES
  );

  const syncFunc = useCallback(() => {
    syncCourses()
      .then((data) => {
        message.success("Courses Synced!");
        console.log("DATA", data);
      })
      .catch((error) => {
        message.error(error);
      });
  }, [syncCourses]);

  return (
    <Spin spinning={loading}>
      <Row
        align="middle"
        style={{
          background: "#ffbb96",
          border: "1px solid #ff4d4f",
          width: "100%",
          padding: "1rem",
        }}
      >
        <Col>
          <SyncOutlined style={{ fontSize: "3rem", marginRight: "1rem" }} />
        </Col>
        <Col style={{ flexGrow: 2 }}>
          <Typography.Title level={3} style={{ marginBottom: 0 }}>
            Gecko Sync
          </Typography.Title>
          <Typography.Text>
            There are pending changes that have not been synced to Gecko. Sync
            now to update the application form.
          </Typography.Text>
        </Col>
        <Col style={{ float: "right" }}>
          <Button danger type="primary" onClick={() => syncFunc()}>
            Sync
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};
