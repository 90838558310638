import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";
import { AppPageLayout } from "../layouts";

export const UnauthorisedPage: React.FC = () => {
  const history = useHistory();
  return (
    <AppPageLayout
      breadcrumbs={[{ title: "Home", path: "/app" }, { title: "Unauthorised" }]}
    >
      <Result
        status="403"
        title="Not Authosised"
        subTitle="You are not authorised to access this page"
        extra={
          <Button onClick={() => history.push("/app")} type="primary">
            Home
          </Button>
        }
      />
    </AppPageLayout>
  );
};
