import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { SchemaLink } from "apollo-link-schema";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { schema } from "./mockAPI";

const apiEndpoint: string | undefined = process.env.REACT_APP_API_ENDPOINT;

const link = apiEndpoint
  ? createHttpLink({ uri: apiEndpoint })
  : new SchemaLink({ schema });

export const createClient = (getToken: () => Promise<string | undefined>) => {
  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(link),
  });
};
