import React from "react";
import { Row, Col, Space, Typography, Spin } from "antd";

export const AuthenticatingPage: React.FC = () => (
  <Row justify="center" align="middle" style={{ height: "100vh" }}>
    <Col>
      <Space direction="vertical" align="center" size="middle">
        <Spin size="large" />
        <Typography.Title style={{ marginBottom: 0 }}>
          Logging You In.
        </Typography.Title>
      </Space>
    </Col>
  </Row>
);
