import React from "react";
import { AppPageLayout } from "../layouts";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import {
  Table,
  Typography,
  Button,
  Space,
  Form,
  Input,
  InputNumber,
  Switch,
  Select,
  Checkbox,
  Divider,
  Spin,
  Result,
  message,
} from "antd";
import { ColumnType } from "antd/lib/table";
import { useHistory, useParams } from "react-router-dom";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Can } from "../components/Authentication";

import {
  GET_COURSES,
  GET_COURSE,
  CREATE_COURSE,
  UPDATE_COURSE,
} from "../queries";
import { GetCourses } from "../__generated__/GetCourses";
import { GetCourse, GetCourseVariables } from "../__generated__/GetCourse";
import {
  CreateCourse,
  CreateCourseVariables,
} from "../__generated__/CreateCourse";
import {
  UpdateCourse,
  UpdateCourseVariables,
} from "../__generated__/UpdateCourse";

import { GeckoSync } from "../components/GeckoSync";

const { Text } = Typography;

export const CourseIndexPage: React.FC = () => {
  const history = useHistory();
  const { data, error, loading, refetch } = useQuery<GetCourses>(GET_COURSES);

  return (
    <AppPageLayout
      title="Courses"
      breadcrumbs={[{ title: "Home", path: "/app" }, { title: "Courses" }]}
    >
      <Helmet>
        <title>Clearing Administration: Courses</title>
      </Helmet>
      <Spin spinning={loading}>
        {error ? (
          <Result
            status="500"
            title={error.name}
            subTitle={error.message}
            extra={
              <>
                <Button onClick={() => history.push("/app")}>Back Home</Button>
                <Button
                  type="primary"
                  onClick={() => refetch().catch(() => {})}
                >
                  Retry
                </Button>
              </>
            }
          />
        ) : (
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            {data &&
              data.courses &&
              data.courses.some((c) => c.geckoSyncStatus === "PENDING") && (
                <Can action="courses:geckosync">
                  <GeckoSync />
                </Can>
              )}
            <Can action="courses:create">
              <Button onClick={() => history.push("/app/config/courses/new")}>
                New Course
              </Button>
            </Can>
            {data && data.courses && <CourseTable courses={data!.courses} />}
          </Space>
        )}
      </Spin>
    </AppPageLayout>
  );
};

const compareFunc = (field: string) => {
  return (a: object, b: object) => {
    const aa = a as any;
    const ba = b as any;
    if (aa[field] < ba[field]) return -1;
    if (aa[field] > ba[field]) return 1;
    return 0;
  };
};

const tableColumns: ColumnType<object>[] = [
  {
    title: "UCAS Code",
    dataIndex: "ucasCourseCode",
    key: "ucasCode",
    sorter: compareFunc("ucasCourseCode"),
    defaultSortOrder: "ascend",
  },
  {
    title: "Course Code",
    dataIndex: "courseCode",
    key: "code",
    sorter: compareFunc("courseCode"),
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    sorter: compareFunc("title"),
  },
  {
    title: "School",
    dataIndex: "school",
    key: "school",
    sorter: compareFunc("school"),
    filters: [
      { text: "None", value: "NONE" },
      { text: "BUS", value: "BUS" },
      { text: "CTA", value: "CTA" },
      { text: "ECS", value: "ECS" },
      { text: "EDU", value: "EDU" },
      { text: "HSK", value: "HSK" },
      { text: "HUM", value: "HUM" },
      { text: "LAW", value: "LAW" },
      { text: "LMS", value: "LMS" },
      { text: "PAM", value: "PAM" },
    ],
    onFilter: (value, record) => {
      const ra = record as any;
      if (value === "NONE") return ra.school === "";
      return ra.school === value;
    },
  },
  {
    align: "center" as const,
    title: "Open",
    dataIndex: "openToApplications",
    key: "open",
    render: (open: boolean) => (
      <FontAwesomeIcon icon={open ? faCheck : faTimes} />
    ),
    filters: [
      { text: "Open", value: true },
      { text: "Closed", value: false },
    ],
    onFilter: (value, record) => {
      const ra = record as any;
      return ra.openToApplications === value;
    },
  },
  {
    align: "center" as const,
    title: "Auto-Offer",
    dataIndex: "automaticOfferEnabled",
    key: "open",
    render: (autoOffer: boolean) => (
      <FontAwesomeIcon icon={autoOffer ? faCheck : faTimes} />
    ),
    filters: [
      { text: "Enabled", value: true },
      { text: "Disabled", value: false },
    ],
    onFilter: (value, record) => {
      const ra = record as any;
      return ra.automaticOfferEnabled === value;
    },
  },
  {
    title: "Status",
    dataIndex: "geckoSyncStatus",
    key: "status",
    render: (status: string) => {
      switch (status) {
        case "SYNCED":
          return (
            <Text strong style={{ color: "#389e0d" }}>
              SYNCED
            </Text>
          );
        case "IN_PROGRESS":
          return (
            <Text strong style={{ color: "#eb2f96" }}>
              IN PROGRESS
            </Text>
          );
        case "PENDING":
          return (
            <Text strong style={{ color: "#fa8c16" }}>
              PENDING
            </Text>
          );
        default:
          return (
            <Text strong style={{ color: "#f5222d" }}>
              UNKNOWN
            </Text>
          );
      }
    },
    filters: [
      { text: "PENDING", value: "PENDING" },
      { text: "SYNCED", value: "SYNCED" },
    ],
    onFilter: (value, record) => {
      const ra = record as any;
      return ra.geckoSyncStatus === value;
    },
  },
  {
    align: "right" as const,
    title: "Actions",
    dataIndex: "id",
    key: "actions",
    render: (id: string) => <RowActions id={id} />,
  },
];

const RowActions: React.FC<{ id: string }> = ({ id }) => {
  const history = useHistory();
  return (
    <Can action="courses:update">
      <Button onClick={() => history.push(`/app/config/courses/${id}`)}>
        Edit
      </Button>
    </Can>
  );
};

const CourseTable: React.FC<{ courses: object[] }> = ({ courses }) => {
  return (
    <>
      <Table columns={tableColumns} dataSource={courses} pagination={false} />
    </>
  );
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  colon: false,
  labelAlign: "left" as const,
};

export const CourseCreatePage: React.FC = () => {
  const history = useHistory();
  const [createCourse, { loading }] = useMutation<
    CreateCourse,
    CreateCourseVariables
  >(CREATE_COURSE, {
    update(cache, { data }) {
      try {
        const { courses }: any = cache.readQuery({ query: GET_COURSES });
        cache.writeQuery({
          query: GET_COURSES,
          data: { courses: courses.concat([data!.createCourse]) },
        });
      } catch {}
    },
  });

  const onFinish = (a: any) => {
    createCourse({
      variables: {
        courseAttributes: a,
      },
    }).then((data) => {
      message.success("Course Created!");
      history.push("/app/config/courses");
    });
  };

  const onCancel = () => {
    history.push("/app/config/courses");
  };

  return (
    <AppPageLayout
      title="New Course"
      breadcrumbs={[
        { title: "Home", path: "/app" },
        { title: "Courses", path: "/app/config/courses" },
        { title: "New" },
      ]}
    >
      <Helmet>
        <title>Clearing Administration: New Course</title>
      </Helmet>
      <Spin spinning={loading}>
        <CourseForm onFinish={onFinish} onCancel={onCancel} />
      </Spin>
    </AppPageLayout>
  );
};

export const CourseUpdatePage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data, error, loading: queryLoading } = useQuery<
    GetCourse,
    GetCourseVariables
  >(GET_COURSE, {
    variables: { id: id },
  });

  const [updateCourse, { loading: mutationLoading }] = useMutation<
    UpdateCourse,
    UpdateCourseVariables
  >(UPDATE_COURSE);
  if (error) return <h1>{error.message}</h1>;

  const onFinish = (a: any) => {
    updateCourse({
      variables: {
        id: data!.course!.id,
        courseAttributes: a,
      },
    }).then((data) => {
      message.success("Course Updated!");
      history.push("/app/config/courses");
    });
  };

  const onCancel = () => {
    history.push("/app/config/courses");
  };

  return (
    <AppPageLayout
      title={data && data.course ? data.course.title : undefined}
      breadcrumbs={[
        { title: "Home", path: "/app" },
        { title: "Courses", path: "/app/config/courses" },
        { title: data && data.course ? data!.course.title : "..." },
      ]}
    >
      <Spin spinning={queryLoading || mutationLoading}>
        {data && data.course && (
          <>
            <Helmet>
              <title>Clearing Administration: {data.course.title}</title>
            </Helmet>
            <CourseForm
              onFinish={onFinish}
              onCancel={onCancel}
              course={data.course}
            />
          </>
        )}
        {data && !data.course && (
          <>
            <Helmet>
              <title>Clearing Administration: Course Not Found</title>
            </Helmet>
            <Result
              status="404"
              title="Course Not Found"
              subTitle="The course you are looking for doesn't exist."
              extra={
                <Button
                  type="primary"
                  onClick={() => history.push("/app/config/courses")}
                >
                  Back to Courses
                </Button>
              }
            />
          </>
        )}
      </Spin>
    </AppPageLayout>
  );
};

interface CourseFormProps {
  onFinish(values: object): void;
  onCancel(): void;
  course?: any;
}

const CourseForm: React.FC<CourseFormProps> = ({
  onFinish,
  course,
  onCancel,
}) => (
  <Form
    {...layout}
    onFinish={(data) => {
      onFinish(
        Object.assign(
          data,
          data.collegeLocations ? {} : { collegeLocations: [] }
        )
      );
    }}
    initialValues={course}
  >
    <Form.Item
      name="title"
      label="Title"
      extra="Displayed to applicants in the course selection list on the clearing application from."
      rules={[{ required: true, message: "Title cannot be empty" }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="courseCode"
      label="Course Code"
      rules={[{ required: true, message: "Course Code cannot be empty" }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="ucasCourseCode"
      label="UCAS Course Code"
      rules={[{ required: true, message: "UCAS Course Code cannot be empty" }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="openToApplications"
      label="Open To Applications"
      extra="Enable to make the course available for application in the clearing application form."
      valuePropName="checked"
    >
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
    </Form.Item>
    <Form.Item
      name="automaticOfferEnabled"
      label="Automatic Offer Enabled"
      valuePropName="checked"
    >
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
    </Form.Item>
    <Form.Item name="collegeLocations" label="College Locations">
      <Checkbox.Group
        options={[
          { label: "Barnet and Southgate College", value: "BAR" },
          { label: "Hertford Regional College", value: "HRC" },
          { label: "Oaklands College", value: "OAK" },
          { label: "North Herts College", value: "NHC" },
          { label: "West Herts College", value: "WHC" },
        ]}
      ></Checkbox.Group>
    </Form.Item>
    <Form.Item
      name="collegeDropdownOption"
      label="College Drop Down Option"
      extra="When enabled, where an applicant has not met the entry requirements, they're application will be referred instead of rejected as there is a relevant college level course available that they should be considered for."
      valuePropName="checked"
    >
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
    </Form.Item>
    <Form.Item name="school" label="School">
      <Select showSearch>
        <Select.Option value=""> </Select.Option>
        <Select.Option value="BUS">Hertfordshire Business School</Select.Option>
        <Select.Option value="LAW">Hertfordshire Law School</Select.Option>
        <Select.Option value="CTA">School of Creative Arts</Select.Option>
        <Select.Option value="EDU">School of Education</Select.Option>
        <Select.Option value="ECS">
          School of Engineering and Computer Science
        </Select.Option>
        <Select.Option value="HSK">
          School of Health and Social Work
        </Select.Option>
        <Select.Option value="HUM">School of Humanities</Select.Option>
        <Select.Option value="LMS">
          School of Life and Medical Sciences
        </Select.Option>
        <Select.Option value="PAM">
          School of Physics, Astronomy and Maths
        </Select.Option>
      </Select>
    </Form.Item>
    <Form.Item name="huntGroupId" label="Hunt Group">
      <Select showSearch>
        <Select.Option value={0}> </Select.Option>
        <Select.Option value={70}>ADMISSIONS-COMPLEX</Select.Option>
        <Select.Option value={71}>ADMISSIONS-PPC</Select.Option>
        <Select.Option value={69}>ADMISSIONS-STANDARD</Select.Option>
        <Select.Option value={48}>BIO-PHARMACOLOGY-HEALTH-SCI</Select.Option>
        <Select.Option value={29}>BUSINESS</Select.Option>
        <Select.Option value={30}>COMPUTER-SCIENCE</Select.Option>
        <Select.Option value={31}>CTA-ART-AND-DESIGN</Select.Option>
        <Select.Option value={67}>CTA-MUSIC</Select.Option>
        <Select.Option value={68}>CTA-SCREEN</Select.Option>
        <Select.Option value={49}>DIETETICS-NUTRITION</Select.Option>
        <Select.Option value={32}>EDUCATION</Select.Option>
        <Select.Option value={33}>ENGINEERING</Select.Option>
        <Select.Option value={50}>GEO-ENVIRONMENT-MANAGEMENT</Select.Option>
        <Select.Option value={44}>HUMANITIES-EXLANG</Select.Option>
        <Select.Option value={45}>HUMANITIES-LANG</Select.Option>
        <Select.Option value={46}>LAW</Select.Option>
        <Select.Option value={43}>MIDWIFERY</Select.Option>
        <Select.Option value={34}>NURSING-ADULT</Select.Option>
        <Select.Option value={35}>NURSING-CHILD</Select.Option>
        <Select.Option value={36}>NURSING-LEARNING-DISABILITY</Select.Option>
        <Select.Option value={37}>NURSING-MENTAL-HEALTH</Select.Option>
        <Select.Option value={54}>OPTOMETRY</Select.Option>
        <Select.Option value={38}>PARAMEDIC-SCIENCE</Select.Option>
        <Select.Option value={52}>PHARMACEUTICAL-SCIENCE</Select.Option>
        <Select.Option value={51}>PHARMACY</Select.Option>
        <Select.Option value={58}>PHYSICS-MATHS</Select.Option>
        <Select.Option value={42}>PHYSIOTHERAPY</Select.Option>
        <Select.Option value={53}>PSYCHOLOGY</Select.Option>
        <Select.Option value={39}>RADIOGRAPHY</Select.Option>
        <Select.Option value={40}>RADIOTHERAPY</Select.Option>
        <Select.Option value={41}>SOCIAL-WORK</Select.Option>
        <Select.Option value={57}>SPORT-EXERCISE-SCIENCE</Select.Option>
        <Select.Option value={55}>SPORTS-STUDIES</Select.Option>
        <Select.Option value={56}>SPORTS-THERAPY</Select.Option>
        <Select.Option value={60}>COL:BUSINESS</Select.Option>
        <Select.Option value={61}>COL:COMPUTING</Select.Option>
        <Select.Option value={62}>COL:CREATIVE-ENTERPRISE</Select.Option>
        <Select.Option value={63}>COL:EXTENDED-ENGINEERING</Select.Option>
        <Select.Option value={64}>COL:INITIAL-YEAR-SCIENCE</Select.Option>
        <Select.Option value={66}>COL:LLB-EXTENDED</Select.Option>
      </Select>
    </Form.Item>
    <Divider />
    <Typography.Title level={2}>Entry Requirements</Typography.Title>
    <Divider />
    <Form.Item
      name="ucasTariffPoints"
      label="UCAS Tariff Points"
      rules={[
        { required: true, message: "UCAS Tariff Points cannot be empty" },
      ]}
    >
      <InputNumber min={0} max={200} style={{ width: "100%" }} />
    </Form.Item>
    <Form.Item name="gcseEnglishLevel" label="GCSE English Level">
      <Select>
        <Select.Option value="NONE">&nbsp;</Select.Option>
        <Select.Option value="1">1</Select.Option>
        <Select.Option value="2">2</Select.Option>
        <Select.Option value="3">3</Select.Option>
        <Select.Option value="4">4</Select.Option>
        <Select.Option value="5">5</Select.Option>
        <Select.Option value="6">6</Select.Option>
        <Select.Option value="7">7</Select.Option>
        <Select.Option value="8">8</Select.Option>
        <Select.Option value="9">9</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item name="gcseMathsLevel" label="GCSE Maths Level">
      <Select>
        <Select.Option value="NONE">&nbsp;</Select.Option>
        <Select.Option value="1">1</Select.Option>
        <Select.Option value="2">2</Select.Option>
        <Select.Option value="3">3</Select.Option>
        <Select.Option value="4">4</Select.Option>
        <Select.Option value="5">5</Select.Option>
        <Select.Option value="6">6</Select.Option>
        <Select.Option value="7">7</Select.Option>
        <Select.Option value="8">8</Select.Option>
        <Select.Option value="9">9</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item
      name="twoPlusALevelQualifications"
      label="Require 2 or more A Level Qualifications"
      valuePropName="checked"
    >
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
    </Form.Item>
    <Divider />
    <Space>
      <Button onClick={() => onCancel()}>Cancel</Button>
      <Button type="primary" htmlType="submit">
        Save
      </Button>
    </Space>
  </Form>
);
