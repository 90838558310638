import React, { useContext, useState } from "react";
import * as Sentry from "@sentry/browser";
import "./App.css";

import { provider } from "./MSALProvider";
import {
  Authentication,
  Login,
  ProtectedRoute,
  authContext,
} from "./components/Authentication";
import { ACCESS_RULES } from "./config";

import { createClient } from "./apolloClient";
import { ApolloProvider } from "react-apollo";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AppPage } from "./pages/App";
import { HomePage } from "./pages/Home";
import {
  CourseIndexPage,
  CourseCreatePage,
  CourseUpdatePage,
} from "./pages/Course";

const sentryDSN: string | undefined = process.env.REACT_APP_SENTRY_DSN;
if (sentryDSN)
  Sentry.init({
    dsn: sentryDSN,
  });

const ApolloProviderWithAuth: React.FC = ({ children }) => {
  const auth = useContext(authContext);
  const [client] = useState(createClient(auth.token));

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

function App() {
  return (
    <div className="App">
      <Router>
        <Authentication provider={provider} rules={ACCESS_RULES}>
          <Switch>
            <Route
              path="/app"
              render={({ match: { url } }) => (
                <Login>
                  <ApolloProviderWithAuth>
                    <Route path={`${url}/`} component={AppPage} exact />
                    <Route
                      path={`${url}/config`}
                      render={({ match: { url } }) => (
                        <Switch>
                          <ProtectedRoute
                            path={`${url}/courses/new`}
                            action="courses:create"
                          >
                            <CourseCreatePage />
                          </ProtectedRoute>
                          <ProtectedRoute
                            path={`${url}/courses/:id`}
                            action="courses:update"
                          >
                            <CourseUpdatePage />
                          </ProtectedRoute>
                          <ProtectedRoute
                            path={`${url}/courses`}
                            action="courses:read"
                          >
                            <CourseIndexPage />
                          </ProtectedRoute>
                        </Switch>
                      )}
                    />
                  </ApolloProviderWithAuth>
                </Login>
              )}
            />
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </Authentication>
      </Router>
    </div>
  );
}

export default App;
